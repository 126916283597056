import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AUTH_TOKEN } from "constants/AuthConstant";

const initialState = {
    loading: false,
    message: "",
    showMessage: false,
    redirect: "",
    // token: localStorage.getItem(AUTH_TOKEN) || null,
    meetingHistory: [],
};

export const getMeetingHistory = createAsyncThunk(
    "meetings/getMeetingHistory",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/admin/meeting-history`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        token: localStorage.getItem(AUTH_TOKEN),
                    },
                }
            );
            if (response.status === 200 && response.data.success === true) {
                const userData = response.data.data;
                return userData;
            } else {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(error.message || "Error");
        }
    }
)

export const meetingsSlice = createSlice({
    name: "meetings",
    initialState,
    reducers: {
        getMeetings: (state) => {
            state.loading = true;
        },
        getMeetingsSuccess: (state, action) => {
            state.loading = false;
            state.meetings = action.payload;
        },
        getMeetingsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMeetingHistory.pending, (state) => {
                state.loading = true;
            })
            .addCase(getMeetingHistory.fulfilled, (state, action) => {
                state.loading = false;
                state.meetingHistory = action.payload;
            })
            .addCase(getMeetingHistory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
})

export const { getMeetings, getMeetingsSuccess, getMeetingsError } = meetingsSlice.actions

export default meetingsSlice.reducer