import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AUTH_TOKEN } from "constants/AuthConstant";

export const initialState = {
    loading: false,
    error: null,
    dashboardData: [{}]
}

export const getDashboardData = createAsyncThunk(
    "dashboard/getDashboardData",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/admin/dashboard-data`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        token: localStorage.getItem(AUTH_TOKEN),
                    },
                }
            );
            if (response.status === 200 && response.data.success === true) {
                const userData = response.data.data;
                return userData;
            } else {
                return rejectWithValue(response.data.message);
            }
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
)

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        getDashboardData: (state) => {
            state.loading = true;
        },

        getDashboardDataSuccess: (state, action) => {
            state.loading = false;
            state.dashboardData = action.payload;
        },

        getDashboardDataError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDashboardData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDashboardData.fulfilled, (state, action) => {
                state.loading = false;
                state.dashboardData = action.payload;
            })
            .addCase(getDashboardData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
})

export const { getDashboardDatas, getDashboardDataSuccess, getDashboardDataError } = dashboardSlice.actions

export default dashboardSlice.reducer