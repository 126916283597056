import { notification } from "antd";

export const logFormData = (formData) => {
    for (const pair of formData.entries()) {
        console.log(`${pair[0]} : ${pair[1]}`);
    }
}

export const sendNotification = ({ type = 'success', message, duration }) => {
    // types = [success, error, info, warning]
    const options = {
        placement: 'topRight',
        duration: duration || 3,
    };
    notification[type]({
        ...options,
        message,
    });
};