/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AUTH_TOKEN } from "constants/AuthConstant";
import { sendNotification } from "utils/utilities";
export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  // token: localStorage.getItem(AUTH_TOKEN) || null,
  isAddingNewUser: false,
  userList: [],
};

export const listAllUsers = createAsyncThunk("user/list", async (data, { rejectWithValue }) => {
  // const { email, password } = data;
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/get-all-users`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          token: localStorage.getItem(AUTH_TOKEN),
        },
      }
    );
    if (response.status === 200 && response.data.success === true) {
      const userData = response.data.data;
      return userData;
    } else {
      return rejectWithValue(response.data.message);
    }
    // const response = await FirebaseService.signInEmailRequest(email, password)
    // if (response.user) {
    // 	const token = response.user.refreshToken;
    // 	localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
    // 	return token;
    // } else {
    // 	return rejectWithValue(response.message?.replace('Firebase: ', ''));
    // }
  } catch (err) {
    return rejectWithValue(err.message || "Error");
  }
});

export const addNewUser = createAsyncThunk("user/add", async (data, { rejectWithValue, dispatch }) => {
  // const { email, password } = data;
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/create-user`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          token: localStorage.getItem(AUTH_TOKEN),
        },
      }
    );
    if (response.status === 200 && response.data.success === true) {
      dispatch(listAllUsers())
      const userData = response.data.data;
      return userData;
    } else {
      sendNotification({ type: "error", message: response.data.message });
      return rejectWithValue(response.data.message);
    }
  } catch (err) {
    return rejectWithValue(err.message || "Error");
  }
});

export const updateUser = createAsyncThunk("user/update", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/update-user`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          token: localStorage.getItem(AUTH_TOKEN),
        },
      }
    );
    if (response.status === 200 && response.data.success === true) {
      const userData = response.data.data;
      sendNotification({ type: "success", message: response.data.message });
      dispatch(listAllUsers());
      return userData;
    } else {
      return rejectWithValue(response.data.message);
    }
  } catch (err) {
    return rejectWithValue(err.message || "Error");
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listAllUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(listAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.userList = action.payload;
      })
      .addCase(listAllUsers.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(addNewUser.pending, (state) => {
        state.isAddingNewUser = true;
      })
      .addCase(addNewUser.fulfilled, (state, action) => {
        state.isAddingNewUser = false;
        // state.userList = action.payload;
      })
      .addCase(addNewUser.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.isAddingNewUser = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.isAddingNewUser = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isAddingNewUser = false;
        // state.userList = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.isAddingNewUser = false;
      });
  },
});

export const { authenticated, showAuthMessage, hideAuthMessage, signOutSuccess, showLoading, signInSuccess } = userSlice.actions;

export default userSlice.reducer;
